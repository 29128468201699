import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { useMedia } from 'react-use';
import { faFacebookF, faLinkedin, faTelegram, faTwitter, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown } from '@fortawesome/pro-light-svg-icons';
import { faLink } from '@fortawesome/pro-regular-svg-icons';
import { Helmet } from 'react-helmet';
import { Collapse } from 'react-collapse';
import * as commonmark from 'commonmark';
import { FormattedMessage, useIntl } from 'react-intl';
import Markdown from '../../components/Markdown';
import Grid from '../../components/Grid';
import Typography from '../../components/Typography';
import Layout from '../../components/Layout';
import { HTMLContent } from '../../components/Content/Content';
import Icon from '../../components/Icon';
import Button from '../../components/Button';
import Card from '../../components/Card';
import CardHeader from '../../components/CardHeader';
import CardBody from '../../components/CardBody';
import getSchemaBreadcrumbs from '../../utils/getSchemaBreadcrumbs';
import Breadcrumbs from '../../components/Breadcrumbs';
import NewsLetterSection from '../../components/NewsletterSection';
import dasherize from '../../utils/dasherize';
import translations from './translations';
import formatLocale from '../../utils/formatLocale';

const PostTemplate = ({
  author,
  seoDescription,
  seoTitle,
  image,
  title,
  timeToRead,
  content,
  location,
  locale,
  datePublished,
  dateModified,
  faq,
  site,
}) => {
  const intl = useIntl();
  const [openCollapse, setOpenCollapse] = useState(null);
  const isMobile = useMedia('(max-width: 991px)');
  const datePublishedInstance = new Date(datePublished);
  const formattedDatePublished =
    // eslint-disable-next-line no-self-compare
    datePublishedInstance.getTime() === datePublishedInstance.getTime()
      ? new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long', day: 'numeric' }).format(
          new Date(datePublished),
        )
      : null;
  const dateModifiedInstance = new Date(datePublished);
  const formattedDateModified =
    // eslint-disable-next-line no-self-compare
    dateModifiedInstance.getTime() === dateModifiedInstance.getTime()
      ? new Intl.DateTimeFormat(locale, { year: 'numeric', month: 'long', day: 'numeric' }).format(
          new Date(dateModified),
        )
      : null;

  const isMobileOrTablet = () => {
    if (typeof window !== `undefined`) {
      return /(android|iphone|ipad|mobile)/i.test(navigator.userAgent);
    }
    return false;
  };

  const handleShare = (url, windowWidth, windowHeight) => () => {
    const getBoxPositionOnWindowCenter = (width, height) => ({
      left: window.outerWidth / 2 + (window.screenX || window.screenLeft || 0) - width / 2,
      top: (window.outerHeight / 2 + (window.screenY || window.screenTop || 0) - height / 2) / 2,
    });

    const shareConfig = {
      height: windowHeight,
      width: windowWidth,
      ...getBoxPositionOnWindowCenter(windowWidth, windowHeight),
      location: 'no',
      toolbar: 'no',
      status: 'no',
      directories: 'no',
      menubar: 'no',
      scrollbars: 'yes',
      resizable: 'no',
      centerscreen: 'yes',
      chrome: 'yes',
    };

    window.open(
      url,
      '',
      Object.keys(shareConfig)
        .map(key => `${key}=${shareConfig[key]}`)
        .join(', '),
    );
  };

  const toggleCollapse = id => () => {
    if (openCollapse === id) {
      setOpenCollapse(null);
      return;
    }

    setOpenCollapse(id);
  };

  const getFirstParagraph = markdown => {
    const reader = new commonmark.Parser();
    const doc = reader.parse(markdown);
    const walker = doc.walker();
    let event;
    let node;
    let firstParagraph = '';

    // eslint-disable-next-line no-cond-assign
    while ((event = walker?.next())) {
      node = event.node;
      if (event.entering && node.type === 'paragraph' && !firstParagraph) {
        firstParagraph = node?.firstChild.literal;
      }
    }

    return firstParagraph;
  };
  const getSecondHeading = markdown => {
    const reader = new commonmark.Parser();
    const doc = reader.parse(markdown);
    const walker = doc.walker();
    let event;
    let node;
    let secondHeading = '';

    // eslint-disable-next-line no-cond-assign
    while ((event = walker?.next())) {
      node = event.node;
      if (event.entering && node.type === 'heading' && node.level === 2 && !secondHeading) {
        secondHeading = node?.firstChild.literal;
      }
    }

    return secondHeading;
  };

  return (
    <>
      <Helmet
        title={`${seoTitle} | Afosto`}
        meta={[
          {
            name: 'description',
            content: seoDescription,
          },
          { name: 'twitter:card', content: 'summary' },
          {
            name: 'twitter:label1',
            content: 'Geschreven door',
          },
          { name: 'twitter:data1', content: author },
          { name: 'twitter:label2', content: 'Geschatte leestijd' },
          { name: 'twitter:data2', content: `${timeToRead} minuten` },
          { property: 'og:title', content: `${title} | Afosto` },
          { property: 'og:type', content: 'article' },
          {
            property: 'og:description',
            content: seoDescription,
          },
          {
            property: 'og:url',
            content: location?.href,
          },
          {
            property: 'og:image',
            content: `${image}?w=1012&h=506&c=1`,
          },
        ]}
      >
        <html lang={formatLocale(locale)} />
        <script type="application/ld+json">
          {`
            [
              ${getSchemaBreadcrumbs(location)}
              ${
                faq?.length > 0
                  ? `{
                          "@context": "https://schema.org",
                          "@type": "FAQPage",
                          "mainEntity": [
                            ${faq.map(
                              item => `
                                {
                                  "@type": "Question",
                                  "name": "${item?.title}",
                                  "acceptedAnswer": {
                                    "@type": "Answer",
                                    "text": "${item?.contents?.[0]?.body?.replace(/["]/g, "'")}"
                                  }
                                }
                              `,
                            )}
                          ]
                        },`
                  : ''
              }
              {
                "@context": "https://schema.org",
                "@type": "NewsArticle",
                "mainEntityOfPage": {
                  "@type": "WebPage",
                  "@id": "${location.href}"
                },
                "url": "${location.href}",
                "headline": "${title}",
                "alternativeHeadline": "${getSecondHeading(content)}",
                "image": [
                  "${image}"
                 ],
                "datePublished": "${formattedDatePublished}",
                "dateModified": "${formattedDateModified}",
                "author": {
                  "@type": "Person",
                  "name": "${author}"
                },
                "publisher": {
                  "@type": "Organization",
                  "name": "${site?.siteMetadata?.siteName}",
                  "logo": {
                    "@type": "ImageObject",
                    "url": "${site?.siteMetadata?.logo}"
                  }
                },
                "abstract": "${seoDescription}",
                "description": "${getFirstParagraph(content)}",
                "timeRequired": "${timeToRead}"
              },
              {
                "@context": "http://schema.org",
                "@type": "Organization",
                "name": "${site?.siteMetadata?.siteName}",
                "logo": "${site?.siteMetadata?.logo}",
                "@id": "${site?.siteMetadata?.company?.kvk}",
                "url": "${site?.siteMetadata?.siteUrl}",
                "foundingDate": "${site?.siteMetadata?.company?.foundingDate}",
                "founders": ["${site?.siteMetadata?.company?.founders.join('", "')}"],
                "contactPoint": {
                  "@context": "http://schema.org",
                  "@type": "contactPoint",
                  "contactType": "customer service",
                  "telephone": "${site?.siteMetadata?.company?.phoneNumber}",
                  "email": "${site?.siteMetadata?.company?.email}"
                },
                "sameAs": ["${site?.siteMetadata?.twitter}", "${site?.siteMetadata?.facebook}"],
                "address": {
                  "@type": "PostalAddress",
                  "streetAddress": "${site?.siteMetadata?.address?.streetAddress}",
                  "addressLocality": "${site?.siteMetadata?.address?.locality}",
                  "addressRegion": "${site?.siteMetadata?.address?.region}",
                  "postalCode": "${site?.siteMetadata?.address?.postalCode}",
                  "addressCountry": "${site?.siteMetadata?.address?.country}"
                }
              }
            ]
          `}
        </script>
      </Helmet>
      <picture className="blog-banner">
        {image && [
          <source srcSet={`${image}?w=575&h=430&c=1 1x, ${image}?w=1150&h=860&c=1 2x`} media="(max-width: 575px)" />,
          <source srcSet={`${image}?w=767&h=331&c=1 1x, ${image}?w=1534&h=662&c=1 2x`} media="(max-width: 767px)" />,
          <source srcSet={`${image}?w=991&h=428&c=1 1x, ${image}?w=1982&h=856&c=1 2x`} media="(max-width: 991px)" />,
          <source srcSet={`${image}?w=1199&h=433&c=1 1x, ${image}?w=2398&h=866&c=1 2x`} media="(max-width: 1199px)" />,
          <source srcSet={`${image}?w=1440&h=520&c=1 1x, ${image}?w=2880&h=1040&c=1 2x`} media="(max-width: 1440px)" />,
          <source srcSet={`${image}?w=2100&h=758&c=1 1x, ${image}?w=4800&h=1516&c=1 2x`} />,
          <img src={`${image}?w=575&h=430&c=1`} className="blog-banner-image" width={575} height={430} alt={title} />,
        ]}
      </picture>
      <Grid container className="pt-24 pt-md-48 pt-lg-64 pb-80 mt-sm-n80 mt-lg-n120 bg-white zi-2 position-relative">
        <Grid row justify="center">
          <Grid column xs={12} md={10} className="px-sm-40 px-md-15">
            <Breadcrumbs location={location} locale={locale} />
            <Typography
              variant={isMobile ? 'h3' : 'h2'}
              component="h1"
              className="mb-24 mb-md-32 mb-lg-48 mt-32 mt-md-24 mt-lg-32"
            >
              {title}
            </Typography>
            <div className="d-flex align-items-center flex-wrap mt-32 mb-48">
              <Typography>{formattedDatePublished}</Typography>
              <div className="divider-vertical text-gray-200 mx-24" />
              <Typography>
                <FormattedMessage
                  {...translations.writtenByShort}
                  values={{ author: <span className="ml-8">{author}</span> }}
                />
              </Typography>
              <div className="divider-vertical text-gray-200 mx-24 d-none d-sm-block" />
              <div className="d-sm-none w-100" />
              <div className="d-flex align-items-center mt-24 mt-sm-0">
                <Typography>
                  <FormattedMessage {...translations.readingTime} values={{ timeToRead }} />
                </Typography>
              </div>
            </div>
            <div className="blog-text">
              <Markdown
                components={{
                  // eslint-disable-next-line react/prop-types
                  p: ({ children }) => (
                    <Typography variant="body" className="mb-32">
                      {children}
                    </Typography>
                  ),
                  // eslint-disable-next-line react/prop-types
                  // h2: ({ children }) => (
                  //   <Typography variant={isMobile ? 'h6' : 'h5'} component="h2" className="mt-48 mt-lg-64 mb-16">
                  //     {children}
                  //   </Typography>
                  // ),
                  // // eslint-disable-next-line react/prop-types
                  // h3: ({ children }) => (
                  //   <Typography variant={isMobile ? 'subtitle-1' : 'h6'} component="h3" className="mt-24 mt-lg-32 mb-16">
                  //     {children}
                  //   </Typography>
                  // ),
                  h1: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                  h2: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography
                        variant={isMobile ? 'h6' : 'h5'}
                        component={headingLevel}
                        className="mt-48 mt-lg-64 mb-16"
                        id={`${dasherize(text)}`}
                      >
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                  h3: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography
                        id={`${dasherize(text)}`}
                        variant={isMobile ? 'subtitle-1' : 'h6'}
                        component={headingLevel}
                        className="mt-24 mt-lg-32 mb-16"
                      >
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                  h4: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography variant="subtitle-2" component={headingLevel} id={`${dasherize(text)}`}>
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                  h5: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                  h6: props => {
                    // eslint-disable-next-line react/prop-types
                    const { children, level, node } = props;
                    const headingLevel = `h${level}`;
                    // eslint-disable-next-line react/prop-types
                    const text = node?.children?.[0]?.value ?? '';
                    return (
                      <Typography variant={headingLevel} id={`${dasherize(text)}`}>
                        <span>
                          {children}
                          <a href={`#${dasherize(text)}`} className="heading-anchor ml-8">
                            <Icon icon={faLink} />
                          </a>
                        </span>
                      </Typography>
                    );
                  },
                }}
              >
                {content}
              </Markdown>
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid container className="mb-40">
        <Grid row justify="center">
          <Grid column xs={12} md={10} lg={8}>
            {faq?.map((item, idx) => (
              <Card className="mb-8" key={item?.title} elevation={0} border>
                <CardHeader
                  onClick={toggleCollapse(idx)}
                  component="button"
                  type="button"
                  disableBorder
                  className="py-16 d-flex align-items-center rounded"
                >
                  <Typography variant="h6" component="span">
                    {item.title}
                  </Typography>
                  <Icon icon={faAngleDown} className="ml-auto" rotation={openCollapse === idx ? 180 : 0} />
                </CardHeader>
                <div>
                  <Collapse isOpened={openCollapse === idx}>
                    <CardBody className="px-32">
                      <Markdown>{item.contents?.[0]?.body}</Markdown>
                    </CardBody>
                  </Collapse>
                </div>
              </Card>
            ))}
          </Grid>
        </Grid>
      </Grid>

      <Grid container>
        <Grid row justify="center">
          <Grid column xs={12} md={10}>
            <div className="blog-footer">
              <Typography className="mb-12">
                <FormattedMessage
                  {...translations.writtenBy}
                  values={{ author: <span className="ml-8">{author}</span> }}
                />
              </Typography>
              <Typography>{formattedDatePublished}</Typography>
            </div>
          </Grid>
        </Grid>
        <Typography variant="h6" component="div" align="center" className="mb-20">
          <FormattedMessage {...translations.shareArticle} />
        </Typography>
        <div className="d-flex mx-n6 mb-64 justify-content-center">
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              title={intl.formatMessage(translations.shareFacebook)}
              onClick={handleShare(
                `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(location?.href)}`,
                550,
                400,
              )}
            >
              <Icon icon={faFacebookF} fixedWidth size="sm" />
            </Button>
          </div>
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              title={intl.formatMessage(translations.shareTwitter)}
              onClick={handleShare(
                `https://twitter.com/intent/tweet?url=${encodeURIComponent(location?.href)}&text=${encodeURIComponent(
                  `${title} - Door ${author}`,
                )}`,
                550,
                400,
              )}
            >
              <Icon icon={faTwitter} fixedWidth size="sm" />
            </Button>
          </div>
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              title={intl.formatMessage(translations.shareWhatsapp)}
              onClick={handleShare(
                `https://${isMobileOrTablet() ? 'api' : 'web'}.whatsapp.com/send/?text=${encodeURIComponent(
                  `${title} - Door ${author}\n${location?.href}`,
                )}`,
                700,
                750,
              )}
            >
              <Icon icon={faWhatsapp} fixedWidth size="sm" />
            </Button>
          </div>
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              title={intl.formatMessage(translations.shareLinkedIn)}
              onClick={handleShare(
                `https://linkedin.com/shareArticle?url=${encodeURIComponent(location?.href)}`,
                750,
                600,
              )}
            >
              <Icon icon={faLinkedin} fixedWidth size="sm" />
            </Button>
          </div>
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              title={intl.formatMessage(translations.shareTelegram)}
              onClick={handleShare(
                `https://telegram.me/share/?url=${encodeURIComponent(location?.href)}&text=${encodeURIComponent(
                  title,
                )}`,
                550,
                400,
              )}
            >
              <Icon icon={faTelegram} fixedWidth size="sm" />
            </Button>
          </div>
          <div className="px-6">
            <Button
              color="primary"
              className="rounded-circle icon-btn p-12"
              component="a"
              href={`mailto:?subject=&body=${location?.href}`}
              title={intl.formatMessage(translations.shareEmail)}
              target="_blank"
            >
              <Icon icon={faEnvelope} fixedWidth size="sm" />
            </Button>
          </div>
        </div>
      </Grid>
      <Grid container>
        <Grid row justify="center">
          <Grid column xs={12} md={8} lg={6} className="px-sm-40 px-md-15 pt-24 pb-40">
            <NewsLetterSection />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

PostTemplate.propTypes = {
  author: PropTypes.string,
  seoDescription: PropTypes.string,
  seoTitle: PropTypes.string,
  content: PropTypes.node.isRequired,
  image: PropTypes.string,
  location: PropTypes.object,
  localizations: PropTypes.arrayOf(
    PropTypes.shape({
      locale: PropTypes.string,
      slug: PropTypes.string,
    }),
  ),
  locale: PropTypes.string,
  timeToRead: PropTypes.number,
  title: PropTypes.string,
  faq: PropTypes.array,
  site: PropTypes.object,
  dateModified: PropTypes.string,
  datePublished: PropTypes.string,
  slug: PropTypes.string,
};

PostTemplate.defaultProps = {
  author: 'Afosto',
  seoDescription: '',
  seoTitle: '',
  site: {},
  image: undefined,
  location: undefined,
  localizations: [],
  locale: 'en',
  timeToRead: 0,
  title: '',
  faq: [],
  dateModified: null,
  datePublished: null,
  slug: undefined,
};

const PostPage = ({ data, location }) => {
  const { site, strapi } = data;
  const { blog: strapiBlog } = strapi;
  const {
    body,
    reading_time: readingTime,
    title,
    author = 'afosto',
    slug,
    primary_image: primaryImage = {},
    published_at: datePublished,
    seo_title: seoTitle,
    seo_description: seoDescription,
    updated_at: dateModified,
    blocks,
    locale,
    localizations,
  } = strapiBlog || {};

  const formattedSlug = `/${locale !== 'en' ? 'nl/' : ''}blog/${slug}/`;

  const formattedLocalizations = localizations.reduce(
    (acc, lang) => [
      ...acc,
      {
        ...lang,
        slug: `/${lang.locale !== 'en' ? 'nl/' : ''}blog/${lang?.slug}/`,
      },
    ],
    [],
  );

  const { url: image } = primaryImage || {};

  return (
    <Layout location={location} locale={locale} localizations={formattedLocalizations} slug={formattedSlug}>
      <PostTemplate
        author={author}
        seoDescription={seoDescription}
        seoTitle={seoTitle}
        datePublished={datePublished}
        dateModified={dateModified}
        image={image}
        title={title}
        timeToRead={Number(readingTime?.split(':')[1])}
        content={body}
        contentComponent={HTMLContent}
        location={location}
        localizations={formattedLocalizations}
        locale={locale}
        site={site}
        faq={blocks}
        slug={formattedSlug}
      />
    </Layout>
  );
};

PostPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

PostPage.defaultProps = {
  data: {},
  location: undefined,
};

export default PostPage;

export const pageQuery = graphql`
  query PostByPath($id: ID!) {
    site {
      siteMetadata {
        logo
        siteUrl
        siteName
        twitter
        facebook
        company {
          foundingDate
          founders
          phoneNumber
          email
        }
        address {
          streetAddress
          locality
          region
          postalCode
          country
        }
      }
    }
    strapi {
      blog(id: $id) {
        title
        slug
        id
        locale
        localizations {
          locale
          slug
        }
        seo_title
        seo_description
        reading_time
        published_at
        author
        updated_at
        body
        primary_image {
          url
        }
        blocks {
          title
          contents {
            body
          }
        }
      }
    }
  }
`;
