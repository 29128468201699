import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'site.components.newsletterSection.title',
    defaultMessage: 'Subscribe for free updates, trainings and content',
    description: 'title for newsletter form',
  },
  emailLabel: {
    id: 'site.components.newsletterSection.fields.email.label',
    defaultMessage: 'Email',
    description: 'label of the email field',
  },
  subscribe: {
    id: 'actions.subscribe',
    defaultMessage: 'Subscribe',
    description: 'label of the subscribe button',
  },
  close: {
    id: 'actions.close',
    defaultMessage: 'Subscribe',
    description: 'label of the subscribe button',
  },
  successTitle: {
    id: 'site.components.newsletterSection.feedback.successTitle',
    defaultMessage: 'Thanks for your subscription',
    description: 'title for the successful subscription',
  },
  errorTitle: {
    id: 'site.components.newsletterSection.feedback.errorTitle',
    defaultMessage: 'Something went wrong, try again.',
    description: 'title for the failed subscription',
  },
});
