import React, { useState } from 'react';
import { object, string } from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AnimatePresence, motion } from 'framer-motion';
import { faCheckCircle, faTimesCircle } from '@fortawesome/pro-regular-svg-icons';
import { FormattedMessage, useIntl } from 'react-intl';
import Input from '../Input';
import Typography from '../Typography';
import Button from '../Button';
import Card from '../Card';
import CardBody from '../CardBody';
import Icon from '../Icon';
import LoadingWrapper from '../LoadingWrapper';
import './style.scss';
import submitActiveCampaignForm from '../../utils/submitActiveCampaignForm';
import trackGoogleEvent from '../../utils/trackGoogleEvent';
import translations from './translations';

const schema = object().shape({
  email: string().email().required(),
});

const NewsLetterSection = () => {
  const intl = useIntl();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isError, setIsError] = useState(false);
  const { register, handleSubmit, errors } = useForm({
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = async data => {
    setIsSubmitting(true);
    try {
      if ((!process.env.NODE_ENV || process.env.NODE_ENV !== 'development') && typeof fbq === 'function') {
        // eslint-disable-next-line no-undef
        fbq('track', 'Newsletter');
      }

      trackGoogleEvent('event', 'blog_registratie_afosto', { email: data?.email });
      submitActiveCampaignForm(data, () => setIsSubmitted(true));

      setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    } catch (error) {
      setIsError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Card elevation={4}>
      <CardBody>
        <LoadingWrapper isLoading={isSubmitting}>
          <AnimatePresence>
            {isSubmitted && (
              <motion.div
                key="overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15, ease: 'easeOut', type: 'tween' }}
              >
                <div className="newsletter-form-overlay">
                  <Icon icon={faCheckCircle} size="4x" className="text-success mb-16" />
                  <Typography variant="h3" component="div" align="center">
                    <FormattedMessage {...translations.successTitle} />
                  </Typography>
                </div>
              </motion.div>
            )}
            {isError && (
              <motion.div
                key="error-overlay"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.15, ease: 'easeOut', type: 'tween' }}
              >
                <div className="newsletter-form-overlay">
                  <Icon icon={faTimesCircle} size="4x" className="text-danger mb-16" />
                  <Typography variant="h4" component="div" align="center" className="mb-32">
                    <FormattedMessage {...translations.errorTitle} />
                  </Typography>
                  <Button onClick={() => setIsError(false)}>
                    <FormattedMessage {...translations.close} />
                  </Button>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <form method="post" onSubmit={handleSubmit(handleSubmitForm)}>
            <input type="hidden" ref={register()} name="u" value="4" />
            <input type="hidden" ref={register()} name="f" value="4" />
            <input type="hidden" ref={register()} name="s" />
            <input type="hidden" ref={register()} name="c" value="0" />
            <input type="hidden" ref={register()} name="m" value="0" />
            <input type="hidden" ref={register()} name="act" value="sub" />
            <input type="hidden" ref={register()} name="v" value="2" />
            <Typography variant="h4" component="div" className="mb-24">
              <FormattedMessage {...translations.title} />
            </Typography>
            <div className="form-group">
              <Input
                type="text"
                label={intl.formatMessage(translations.emailLabel)}
                id="email"
                name="email"
                ref={register()}
                errors={errors}
              />
            </div>
            <Button type="submit">
              <FormattedMessage {...translations.subscribe} />
            </Button>
          </form>
        </LoadingWrapper>
      </CardBody>
    </Card>
  );
};

export default NewsLetterSection;
