import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import clsx from 'clsx';

const CardHeader = ({ children, disableBorder, className, component: Component, ...props }) => (
  <Component className={clsx(className, 'card-header', { 'border-0': disableBorder })} {...props}>
    {children}
  </Component>
);

CardHeader.propTypes = {
  disableBorder: PropTypes.bool,
  children: PropTypes.any,
  className: PropTypes.string,
  component: PropTypes.elementType,
};

CardHeader.defaultProps = {
  disableBorder: false,
  children: undefined,
  className: undefined,
  component: 'div',
};

export default CardHeader;
