import { defineMessages } from 'react-intl';

export default defineMessages({
  writtenBy: {
    id: 'site.pages.posts.writtenBy',
    defaultMessage: 'Written by: {author}',
    description: 'label for the author',
  },
  writtenByShort: {
    id: 'site.pages.posts.writtenByShort',
    defaultMessage: 'By: {author}',
    description: 'short label for the author',
  },
  readingTime: {
    id: 'site.pages.posts.readingTime',
    defaultMessage: '{timeToRead} min. reading time',
    description: 'label for the reading time',
  },
  shareArticle: {
    id: 'site.pages.posts.shareArticle',
    defaultMessage: 'Share this article',
    description: 'title for sharing the article',
  },
  shareFacebook: {
    id: 'site.pages.posts.socialShare.facebook',
    defaultMessage: 'Share on Facebook',
    description: 'title for sharing on facebook',
  },
  shareTwitter: {
    id: 'site.pages.posts.socialShare.twitter',
    defaultMessage: 'Share on Twitter',
    description: 'title for sharing on twitter',
  },
  shareWhatsapp: {
    id: 'site.pages.posts.socialShare.whatsApp',
    defaultMessage: 'Share on WhatsApp',
    description: 'title for sharing on whatsapp',
  },
  shareTelegram: {
    id: 'site.pages.posts.socialShare.telegram',
    defaultMessage: 'Share on Telegram',
    description: 'title for sharing on telegram',
  },
  shareLinkedIn: {
    id: 'site.pages.posts.socialShare.linkedIn',
    defaultMessage: 'Share on LinkedIn',
    description: 'title for sharing on linkedIn',
  },
  shareEmail: {
    id: 'site.pages.posts.socialShare.email',
    defaultMessage: 'Share via email',
    description: 'title for sharing via email',
  },
});
